import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/filters'

Vue.config.productionTip = false

//Third part plugins
import i18n from '@/plugins/vue-i18n.js'
import vuetify from './plugins/vuetify'
import './plugins/vue-meta'

//Component
import layout from '@/components/layouts/Layout'
import content1 from '@/components/content/Content-1.vue'
Vue.component('layout', layout)
Vue.component('Content1', content1)

//Customize Style
import '@/assets/sass/init.scss'

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
