export const uiHelper = {
  /* Local Storage */
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  /* Session Storage */
  getSessionStorage,
  setSessionStorage,
  removeSessionStorage,
  /* Cookie */
  setCookie,
  getCookie,
  removeCookie,
  /* Location */
  reloadPage,
  /* Window */
  encodedString,
  decodedString,
  /* Others */
  removeHtmlTags,
  /* Variable Transform */
  arrayToTrimmedStringWithSpace
}

function getLocalStorage(keyName) {
  return localStorage.getItem(keyName)
}

function setLocalStorage(keyName, keyValue) {
  localStorage.setItem(keyName, keyValue)
}

function removeLocalStorage(keyName) {
  localStorage.removeItem(keyName)
}

function getSessionStorage(keyName) {
  return sessionStorage[keyName]
}

function setSessionStorage(keyName, keyValue) {
  sessionStorage.setItem(keyName, keyValue)
}

function removeSessionStorage(keyName) {
  sessionStorage.removeItem(keyName)
}

function setCookie(keyName, keyValue, exDays = 30) {
  let date = new Date()
  date.setTime(date.getTime() + exDays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + date.toUTCString()
  document.cookie = keyName + '=' + keyValue + ';' + expires + ';path=/'
}

function getCookie(keyName) {
  let name = keyName + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let cookies = decodedCookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i]
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }
  return null
}

function removeCookie(keyName) {
  document.cookie = `${keyName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

function reloadPage() {
  location.reload()
}

function encodedString(value) {
  return window.btoa(value)
}

function decodedString(value) {
  return window.atob(value)
}

function removeHtmlTags(str) {
  if (str === null || str === '') return false
  else str = str.toString()

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '')
}

function arrayToTrimmedStringWithSpace(array, trimStart) {
  return array.map((x) => x.substring(trimStart)).join(', ')
}
