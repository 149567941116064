<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import { i18nService } from '@/services'
import { i18nHelper } from '@/utils'
import { SHARED } from '@/constants'

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: i18nHelper.getMessage('title.projectName'),
    // all titles will be injected into this template
    titleTemplate: '%s'
  },
  computed: {
    layout() {
      return this.$route.meta.layout || SHARED.DEFAULT_LAYOUT
    }
  },
  data: () => ({
    //
  }),
  created() {
    this.initApp()
  },
  methods: {
    initApp() {
      i18nService.initI18n()
    }
  }
}
</script>
