var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('v-app-bar',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"intersect",rawName:"v-intersect.once",value:(_vm.onIntersectHeader),expression:"onIntersectHeader",modifiers:{"once":true}}],staticClass:"header-app-bar",class:[
      _vm.isExpandAppBar ? 'mobile-nav-active' : 'close-mobile-nav',
      { mobile: _vm.$vuetify.breakpoint.smAndDown }
    ],attrs:{"flat":"","fixed":"","height":_vm.$vuetify.breakpoint.mdAndUp ? 80 : 60,"color":_vm.appBarColor}},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"d-flex justify-space-between align-center wh-100"},[_c('v-slide-y-transition',[(_vm.isIntersectingHeader)?_c('div',{staticClass:"d-flex cursor-pointer",style:("\n                transition-duration: 0.5s !important;"),on:{"click":function($event){return _vm.toPage(_vm.routeName.HOME)}}},[_c('v-img',{attrs:{"max-width":_vm.$vuetify.breakpoint.mdAndUp ? '230' : '180',"src":((_vm.shared.BASE_URL) + "img/logo/logo-" + _vm.appBarLogo + ".png")}})],1):_vm._e()]),_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-slide-y-transition',{ref:"test",staticClass:"d-flex h-100",attrs:{"group":true,"tag":"div"}},[_vm._l((_vm.navList),function(i,index){return [(_vm.isIntersectingHeader)?_c('div',{key:index,staticClass:"h-100 d-flex justify-center align-center px-2 px-lg-5 cursor-pointer nav-option text-capitalize header__nav-list-desktop",class:_vm.colorText(i.route),on:{"click":function($event){return _vm.toPage(i.route)}}},[_c('div',[_vm._v(_vm._s(_vm.$t(("text." + (i.name)))))])]):_vm._e()]})],2):_c('v-app-bar-nav-icon',{staticClass:"surface--text",on:{"click":function($event){_vm.isExpandAppBar = !_vm.isExpandAppBar}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isExpandAppBar)?_c('v-icon',[_vm._v("mdi-close")]):_c('v-icon',[_vm._v("mdi-menu")])],1)],1)],1),(_vm.isExpandAppBar)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersectExpandAppBar),expression:"onIntersectExpandAppBar"}],staticClass:"pa-12"},[_c('v-scroll-x-transition',{attrs:{"group":true}},[_vm._l((_vm.navList),function(i,index){return [(_vm.isIntersectingExpandAppBar)?_c('div',{key:index,staticClass:"text-h5 mb-12 text-capitalize text-decoration-none",class:[
                _vm.currentRouteName == i.route
                  ? 'primary--text'
                  : 'surface--text'
              ],style:(("\n               transition-delay: 0." + (3 + index) + "s !important;\n                transition-duration: 1s !important;")),on:{"click":function($event){return _vm.toPage(i.route)}}},[_vm._v(" "+_vm._s(_vm.$t(("text." + (i.name))))+" ")]):_vm._e()]})],2)],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }