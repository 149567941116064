import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FECF8D',
        secondary: '#CADBE9',
        tertiary: '#0D0D12',
        accent: '#639FCD',
        error: '#ef476f',
        text: '#e1dfe9',
        label: '#838383',
        title: '#424242',
        greyDivider: '#C4C4C4',
        greyCard: '#F8F8FA',
        disabled: '#565360',
        background: '#ffffff',
        surface: '#ffffff',
        darkLayer: '#161515'
      }
    },
    options: { customProperties: true }
  },
  breakpoint: {
    mobileBreakpoint: 'sm' // This is equivalent to a value of 960
  }
})
