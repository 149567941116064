<template>
  <div class="wh-100">
    <Header></Header>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <Footer></Footer>
    <AppLoading></AppLoading>
    <AppSnackBar
      v-model="snackBar.show"
      :color="snackBar.color"
      :timeout="snackBar.timeout"
      :message="snackBar.message"
    ></AppSnackBar>
  </div>
</template>

<script>
import Header from '@/components/layouts/Header'
import Footer from '@/components/layouts/Footer'
import AppLoading from '@/components/base/AppLoading'
import AppSnackBar from '@/components/base/AppSnackBar'

export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    AppLoading,
    AppSnackBar
  },
  data() {
    return {
      offsetTaop: 0,
      snackBar: {
        show: false,
        color: '',
        message: '',
        timeout: null
      }
    }
  },
  methods: {
    openSnackBar(color, message, timeout) {
      this.resetSnackBar()
      this.snackBar = {
        show: true,
        color: color,
        message: message,
        timeout: timeout
      }
    },
    resetSnackBar() {
      this.snackBar = {
        show: false,
        color: '',
        message: '',
        timeout: null
      }
    },
    onScroll() {
      this.offsetTop = 0
    }
  }
}
</script>

<style></style>
