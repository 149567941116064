const SHARED = Object.freeze({
  DEFAULT_LOCALE: 'en',
  FALLBACK_LOCALE: 'en',
  DEFAULT_LAYOUT: 'layout',
  BASE_URL: process.env.BASE_URL
})

const LOCAL = Object.freeze({
  I18N_NAME: 'language'
})

const ROUTE_NAME = Object.freeze({
  HOME: 'home',
  ABOUT_US: 'about-us',
  OUR_EXPERTISE: 'our-expertise',
  OUR_LAWYERS: 'our-lawyers',
  CALCULATOR: 'calculator',
  NEWS_AND_EVENTS: 'news-and-events',
  CONTACT_US: 'contact-us'
})

const API = Object.freeze({
  BASE_API_URL: process.env.VUE_APP_BASE_API_URL
})

export { SHARED, LOCAL, ROUTE_NAME, API }
