import { apiService } from '@/services'

export const NEWS_AND_EVENTS = {
  getNewsAndEvents
}

const URL = {
  NEWS_AND_EVENTS: 'news-and-events',
  JSON: 'json',
  LIST: 'list'
}

function getNewsAndEvents(data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  }
  return apiService().get(`/${URL.NEWS_AND_EVENTS}`, requestOptions)
}
