import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import { ROUTE_NAME } from '@/constants'

Vue.use(VueRouter)
Vue.use(Meta)

const scrollBehavior = () => {
  return { x: 0, y: 0 }
}

const routes = [
  {
    path: '/',
    name: ROUTE_NAME.HOME,
    component: () => import('@/views/Home.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/about-us',
    name: ROUTE_NAME.ABOUT_US,
    component: () => import('@/views/AboutUs.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/our-expertise',
    name: ROUTE_NAME.OUR_EXPERTISE,
    component: () => import('@/views/OurExpertise.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/our-lawyers',
    name: ROUTE_NAME.OUR_LAWYERS,
    component: () => import('@/views/OurLawyers.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/calculator',
    name: ROUTE_NAME.CALCULATOR,
    component: () => import('@/views/Calculator.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/news-events',
    name: ROUTE_NAME.NEWS_AND_EVENTS,
    component: () => import('@/views/NewsAndEvents.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/contact-us',
    name: ROUTE_NAME.CONTACT_US,
    component: () => import('@/views/ContactUs.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/',
    redirect: { name: ROUTE_NAME.HOME }
  },
  {
    path: '*',
    redirect: { name: ROUTE_NAME.HOME }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

export default router
