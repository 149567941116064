import { i18nHelper } from '@/utils'
import { LANGUAGE } from '@/constants/enums'

export const listService = {
  availableLanguageList
}

function availableLanguageList() {
  let list = []

  list.push({
    text: i18nHelper.getMessage(`enumLanguage.${LANGUAGE.ENGLISH}`),
    value: LANGUAGE.ENGLISH
  })

  list.push({
    text: i18nHelper.getMessage(`enumLanguage.${LANGUAGE.CHINESE}`),
    value: LANGUAGE.CHINESE
  })

  return list
}
