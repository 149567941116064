<template>
  <div v-intersect.once="onIntersect" class="content-1">
    <v-scroll-x-transition>
      <div
        v-if="isIntersecting"
        class="text-h4 text-md-h3 font-weight-bold font-cormorant title--text text-center text-capitalize mb-4 mb-md-8"
        style="transition-duration: 0.5s !important"
      >
        {{ title }}
      </div>
    </v-scroll-x-transition>
    <div v-if="description" class="text-center px-md-10">
      <v-scroll-x-transition>
        <div
          v-if="isIntersecting"
          class="label--text text-body-2 text-lg-body-1 description"
          v-html="description"
          style="
            transition-delay: 0.5s !important;
            transition-duration: 0.5s !important;
          "
        ></div>
      </v-scroll-x-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Content1',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isIntersecting: false
  }),
  methods: {
    onIntersect(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersecting = isIntersecting
      }
    }
  }
}
</script>

<style lang="scss">
.content-1 {
  .description {
    strong {
      color: var(--v-title-base);
    }
  }
}
</style>
