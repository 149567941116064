import { API } from '@/constants'

export const apiHelper = {
  sortOutMessage,
  removeParamsWithoutValue,
  dateTimeFrom,
  dateTimeTo
}

function sortOutMessage(data) {
  let message = []
  let newMessages = []

  if (typeof data === 'string') {
    newMessages.push(data)
  } else {
    message = Object.values(data)
    message.forEach((v) => {
      newMessages.push(v[0])
    })
  }

  return newMessages
}

function removeParamsWithoutValue(obj) {
  for (var keyName in obj) {
    if (
      obj[keyName] === null ||
      obj[keyName] === undefined ||
      obj[keyName] === ''
    ) {
      delete obj[keyName]
    }
  }
  return obj
}

function dateTimeTo(date) {
  if (date != '' && date != null && date != undefined) {
    date = new Date(date + API.DATETIME_TO_END_TIME).getTime()
  } else {
    date = ''
  }
  return date
}

function dateTimeFrom(date) {
  if (date != '' && date != null && date != undefined) {
    date = new Date(date + API.DATETIME_FROM_START_TIME).getTime()
  } else {
    date = ''
  }
  return date
}
