import i18n from '@/plugins/vue-i18n'
import { uiHelper } from '@/utils'
import { LOCAL } from '@/constants'

export const i18nHelper = {
  getLanguage,
  setLanguage,
  getMessage
}

function getLanguage() {
  return uiHelper.getLocalStorage(LOCAL.I18N_NAME)
}

function setLanguage(keyValue) {
  uiHelper.setLocalStorage(LOCAL.I18N_NAME, keyValue)
  i18n.locale = keyValue
}

function getMessage(key, value) {
  return i18n.t(key, value)
}
