/* Define Module Name */
const MODULE_NAME = 'shared/'

/* Define Action Name */
const A_OPEN_LOADING = 'openLoading'
const A_CLOSE_LOADING = 'closeLoading'

/* Define Getter Name */

/* Define Mutation Name */
const M_OPEN_LOADING = 'setOpenLoading'
const M_CLOSE_LOADING = 'setCloseLoading'

/* Define Export Name */
export const SHARED_OPEN_LOADING = MODULE_NAME + A_OPEN_LOADING
export const SHARED_CLOSE_LOADING = MODULE_NAME + A_CLOSE_LOADING

const state = {
  isLoading: false
}

//Getters
const getters = {}

// Actions
const actions = {
  [A_OPEN_LOADING]({ commit }) {
    commit(M_OPEN_LOADING)
  },
  [A_CLOSE_LOADING]({ commit }) {
    commit(M_CLOSE_LOADING)
  }
}

// Mutations
const mutations = {
  [M_OPEN_LOADING](state) {
    state.isLoading = true
  },
  [M_CLOSE_LOADING](state) {
    state.isLoading = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
