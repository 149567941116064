<template>
  <v-footer
    app
    absolute
    :min-height="isMobile ? 48 : 85"
    color="tertiary"
    class="px-0"
  >
    <div class="d-flex justify-space-between content-wrapper">
      <div class="surface--text text-caption text-md-body-1">
        {{ $t('text.footerCopyRight') }}
      </div>
      <div class="d-flex">
        <template v-for="(i, index) in socialMediaIcons">
          <a :key="index" :href="i.url" target="_blank">
            <v-img
              :max-width="isMobile ? 20 : 24"
              :max-height="isMobile ? 20 : 24"
              :src="`${shared.BASE_URL}img/icons/${i.key}.png`"
              :alt="i.key"
              :class="{ 'mr-4 mr-md-6': index == 0 }"
            >
            </v-img>
          </a>
        </template>
      </div>
    </div>
  </v-footer>
</template>

<script>
import { SHARED } from '@/constants'

export default {
  name: 'Footer',
  data: () => ({
    shared: SHARED,
    socialMediaIcons: [
      {
        key: 'facebook',
        url: 'https://www.facebook.com/LumKK.Co'
      },
      {
        key: 'whatsapp',
        url: 'https://wa.me/60177508408'
      }
    ]
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  }
}
</script>

<style></style>
