import { CONTACT_APPLICATION } from '@/api'
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module'
import { apiHelper } from '@/utils'

/* Define Module Name */
const MODULE_NAME = 'contactApplication/'

/* Define Action Name */
const A_CREATE_CONTACT_APPLICATION = 'createContactApplication'
/* Define Reset State Action Name */
const A_INITIAL_CREATE_CONTACT_APPLICATION_STATE =
  'initialCreateContactApplicationState'

/* Define Getter Name */

/* Define Mutation Name */
const M_CREATE_CONTACT_APPLICATION = 'setCreateContactApplication'

/* Define Reset State Mutation Name */
const M_INITIAL_CREATE_CONTACT_APPLICATION_STATE =
  'setInitialCreateContactApplicationState'

/* Define Export Name */
export const CONTACT_APPLICATION_CREATE_CONTACT_APPLICATION =
  MODULE_NAME + A_CREATE_CONTACT_APPLICATION

/* Define Reset State Name */
export const CONTACT_APPLICATION_INITIAL_CREATE_CONTACT_APPLICATION_STATE =
  MODULE_NAME + A_INITIAL_CREATE_CONTACT_APPLICATION_STATE

const state = {
  createContactApplication: {
    code: 0,
    complete: false,
    message: null,
    data: null
  }
}

//Getters
const getters = {}

// Actions
const actions = {
  async [A_CREATE_CONTACT_APPLICATION]({ dispatch, commit }, { data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    await CONTACT_APPLICATION.createContactApplication(data)
      .then((response) => {
        result = response
        commit(M_CREATE_CONTACT_APPLICATION, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_CREATE_CONTACT_APPLICATION, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_INITIAL_CREATE_CONTACT_APPLICATION_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_CONTACT_APPLICATION_STATE)
  }
}

// Mutations
const mutations = {
  [M_CREATE_CONTACT_APPLICATION](state, result) {
    state.createContactApplication = {
      code: 0,
      complete: true,
      message: apiHelper.sortOutMessage(result.message),
      data: null
    }
  },

  [M_INITIAL_CREATE_CONTACT_APPLICATION_STATE](state) {
    state.createContactApplication = {
      code: 0,
      complete: false,
      data: null,
      message: null
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
