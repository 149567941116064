<template>
  <div class="header">
    <v-app-bar
      flat
      fixed
      :height="$vuetify.breakpoint.mdAndUp ? 80 : 60"
      :color="appBarColor"
      class="header-app-bar"
      :class="[
        isExpandAppBar ? 'mobile-nav-active' : 'close-mobile-nav',
        { mobile: $vuetify.breakpoint.smAndDown }
      ]"
      v-scroll="onScroll"
      v-intersect.once="onIntersectHeader"
    >
      <div class="content-wrapper">
        <div class="d-flex justify-space-between align-center wh-100">
          <v-slide-y-transition>
            <div
              v-if="isIntersectingHeader"
              class="d-flex cursor-pointer"
              @click="toPage(routeName.HOME)"
              :style="`
                  transition-duration: 0.5s !important;`"
            >
              <v-img
                :max-width="$vuetify.breakpoint.mdAndUp ? '230' : '180'"
                :src="`${shared.BASE_URL}img/logo/logo-${appBarLogo}.png`"
              ></v-img>
            </div>
          </v-slide-y-transition>

          <v-spacer></v-spacer>

          <v-slide-y-transition
            :group="true"
            tag="div"
            v-if="$vuetify.breakpoint.mdAndUp"
            class="d-flex h-100"
            ref="test"
          >
            <template v-for="(i, index) in navList">
              <div
                v-if="isIntersectingHeader"
                :key="index"
                class="h-100 d-flex justify-center align-center px-2 px-lg-5 cursor-pointer nav-option text-capitalize header__nav-list-desktop"
                :class="colorText(i.route)"
                @click="toPage(i.route)"
              >
                <div>{{ $t(`text.${i.name}`) }}</div>
              </div>
            </template>
          </v-slide-y-transition>

          <v-app-bar-nav-icon
            class="surface--text"
            v-else
            @click="isExpandAppBar = !isExpandAppBar"
          >
            <transition name="fade">
              <v-icon v-if="isExpandAppBar">mdi-close</v-icon>
              <v-icon v-else>mdi-menu</v-icon>
            </transition>
          </v-app-bar-nav-icon>
        </div>

        <div
          v-if="isExpandAppBar"
          v-intersect="onIntersectExpandAppBar"
          class="pa-12"
        >
          <v-scroll-x-transition :group="true">
            <template v-for="(i, index) in navList">
              <div
                v-if="isIntersectingExpandAppBar"
                :key="index"
                class="text-h5 mb-12 text-capitalize text-decoration-none"
                :class="[
                  currentRouteName == i.route
                    ? 'primary--text'
                    : 'surface--text'
                ]"
                :style="`
                 transition-delay: 0.${3 + index}s !important;
                  transition-duration: 1s !important;`"
                @click="toPage(i.route)"
              >
                {{ $t(`text.${i.name}`) }}
              </div>
            </template>
          </v-scroll-x-transition>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { listService } from '@/services'
import { i18nHelper } from '@/utils'
import { ROUTE_NAME, SHARED } from '@/constants'

export default {
  name: 'Header',
  data: () => ({
    list: listService,
    routeName: ROUTE_NAME,
    shared: SHARED,
    currentLanguage: i18nHelper.getLanguage(),
    navList: [
      {
        name: 'aboutUs',
        route: ROUTE_NAME.ABOUT_US
      },
      {
        name: 'ourExpertise',
        route: ROUTE_NAME.OUR_EXPERTISE
      },
      {
        name: 'ourLawyers',
        route: ROUTE_NAME.OUR_LAWYERS
      },
      {
        name: 'news&Events',
        route: ROUTE_NAME.NEWS_AND_EVENTS
      },
      {
        name: 'contactUs',
        route: ROUTE_NAME.CONTACT_US
      }
    ],
    isTransparent: true,
    items: [
      { title: 'Home', icon: 'mdi-home-city' },
      { title: 'My Account', icon: 'mdi-account' },
      { title: 'Users', icon: 'mdi-account-group-outline' }
    ],
    isExpandAppBar: false,
    isIntersectingHeader: false,
    isIntersectingExpandAppBar: false
  }),
  computed: {
    currentRouteName() {
      return this.$route.name
    },
    appBarColor() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.isTransparent ? 'transparent' : 'tertiary'
      } else {
        return 'tertiary'
      }
    },
    appBarLogo() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.currentRouteName == 'home'
          ? 'white'
          : this.isTransparent
          ? 'black'
          : 'white'
      } else {
        return 'white'
      }
    }
  },
  watch: {
    isExpandAppBar() {
      if (!this.isExpandAppBar) {
        this.isIntersectingExpandAppBar = false
      }
    }
  },
  methods: {
    onIntersectExpandAppBar(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingExpandAppBar = isIntersecting
      }
    },
    onIntersectHeader(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingHeader = isIntersecting
      }
    },
    colorText(route) {
      let name = this.currentRouteName
      let style = ''

      if (name == route) {
        style = 'primary--text'
      } else {
        if (name == 'home') {
          style = 'surface--text'
        } else {
          style = this.isTransparent ? 'title--text' : 'surface--text'
        }
      }

      return style
    },
    toPage(route) {
      if (this.currentRouteName != route) {
        this.$router.push({ name: route })
      }

      this.isExpandAppBar = false
    },
    changeLanguage(language) {
      i18nHelper.setLanguage(language)
      this.currentLanguage = i18nHelper.getLanguage()
    },
    onScroll() {
      this.isTransparent = window.scrollY < 80
    }
  }
}
</script>

<style lang="scss">
.header-app-bar {
  &.mobile {
    transition: all 0.5s ease-in-out !important;
  }
  .nav-option {
    &:hover {
      color: var(--v-primary-base) !important;
    }
  }

  &.mobile-nav-active {
    height: 100vh !important;
  }
}
</style>
