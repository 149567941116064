<template>
  <v-snackbar
    v-model="snakeBarShow"
    app
    right
    top
    :color="color"
    :timeout="timeout"
    transition="scroll-x-reverse-transition"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn color="base" icon v-bind="attrs" @click="input(false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'AppSnackBar',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    timeout: {
      type: Number,
      default: null
    },
    message: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    snakeBarShow: false
  }),
  watch: {
    value() {
      this.snakeBarShow = this.value
    },
    snakeBarShow() {
      if (this.snakeBarShow != this.value) {
        this.input(this.snakeBarShow)
      }
    }
  },
  methods: {
    input(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style></style>
