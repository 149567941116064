import Vue from 'vue'
import Vuex from 'vuex'

import newsAndEvents from './news-and-events.module'
import shared from './shared.module'
import contactApplication from './contact-application.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    newsAndEvents,
    shared,
    contactApplication
  }
})
