import { apiService } from '@/services'

export const CONTACT_APPLICATION = {
  createContactApplication
}

const URL = {
  CONTACT_APPLICATION: 'contact-application'
}

function createContactApplication(data) {
  let requestBody = {
    name: data.name,
    phone_number: data.phoneNumber,
    email: data.email,
    description: data.description
  }
  return apiService().post(`/${URL.CONTACT_APPLICATION}`, requestBody)
}
