<template>
  <v-overlay opacity="0.6" :value="isLoading" z-index="333">
    <v-progress-circular
      indeterminate
      size="60"
      width="5"
      color="primary"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'AppLoadingLinear',
  computed: {
    isLoading() {
      return this.$store.state.shared.isLoading
    }
  }
}
</script>

<style lang="scss">
.loading-overlay {
  .v-overlay__content {
    height: 100%;
    width: 100%;
  }
}
</style>
