import { NEWS_AND_EVENTS } from '@/api'
import { apiHelper } from '@/utils'
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module'

/* Define Module Name */
const MODULE_NAME = 'newsAndEvents/'

/* Define Action Name */
const A_GET_NEWS_AND_EVENTS = 'getNewsAndEvents'
/* Define Reset State Action Name */
const A_INITIAL_GET_NEWS_AND_EVENTS_STATE = 'initialGetNewsAndEventsState'

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_NEWS_AND_EVENTS = 'setGetNewsAndEvents'

/* Define Reset State Mutation Name */
const M_INITIAL_GET_NEWS_AND_EVENTS_STATE = 'setInitialGetNewsAndEventsState'

/* Define Export Name */
export const NEWS_AND_EVENTS_GET_NEWS_AND_EVENTS =
  MODULE_NAME + A_GET_NEWS_AND_EVENTS

/* Define Reset State Name */
export const NEWS_AND_EVENTS_INITIAL_GET_NEWS_AND_EVENTS_STATE =
  MODULE_NAME + A_INITIAL_GET_NEWS_AND_EVENTS_STATE

const state = {
  newsAndEvents: {
    code: 0,
    complete: false,
    message: null,
    data: [],
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  }
}

//Getters
const getters = {}

// Actions
const actions = {
  async [A_GET_NEWS_AND_EVENTS]({ dispatch, commit }, { data }) {
    let result
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true })
    await NEWS_AND_EVENTS.getNewsAndEvents(data)
      .then((response) => {
        result = response
        commit(M_GET_NEWS_AND_EVENTS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
      .catch((error) => {
        result = error
        commit(M_GET_NEWS_AND_EVENTS, result)
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true })
      })
  },
  [A_INITIAL_GET_NEWS_AND_EVENTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_NEWS_AND_EVENTS_STATE)
  }
}

// Mutations
const mutations = {
  [M_GET_NEWS_AND_EVENTS](state, result) {
    if (result.code == 0) {
      let p = result.pagination

      state.newsAndEvents = {
        code: 0,
        complete: true,
        message: null,
        data: result.data.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            title: x.title,
            description: x.description ? x.description : '-',
            link: x.link,
            image: x.image,
            createdAt: x.created_at
          }
        }),
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      }
    } else {
      state.newsAndEvents = {
        code: 0,
        complete: true,
        message: apiHelper.sortOutMessage(result.message),
        data: null,
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      }
    }
  },

  [M_INITIAL_GET_NEWS_AND_EVENTS_STATE](state) {
    state.newsAndEvents = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
