import { i18nHelper } from '@/utils'
import { SHARED } from '@/constants'

export const i18nService = {
  initI18n
}

function initI18n() {
  if (!i18nHelper.getLanguage()) {
    i18nHelper.setLanguage(SHARED.DEFAULT_LOCALE)
  }
}
